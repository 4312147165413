import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';

// Material UI
import { Card, CardContent, Grid } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

// WRM
import { requestApi } from 'api/request-api';
import useMounted from 'hooks/use-mounted';
import CardHeader from 'components/shared/CardHeader';
import LoadingSpinner from 'components/LoadingSpinner';

const columns = [
  {
    field: 'date',
    headerName: 'Date',
    flex: 1,
  },
  {
    field: 'orderUnitCount',
    headerName: 'Subscriptions',
    flex: 1,
  },
  {
    field: 'orderUnitCountAnalysis',
    headerName: '10/20/50/100/Infinity+',
    flex: 2,
  },
  {
    field: 'publishedQuizVersionCount',
    headerName: 'Custom quizzes published',
    flex: 2,
  },
  {
    field: 'quizVersionShareCount',
    headerName: 'Quizzes shared',
    flex: 2,
  },
  {
    field: 'quizSessionCount',
    headerName: 'Quiz sessions (a student taking a quiz)',
    flex: 2,
  },
  {
    field: 'questionResultCount',
    headerName: 'Correct/incorrect answers',
    flex: 2,
  },
];

function ExportContainer() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
    </GridToolbarContainer>
  );
}

const InfinityAnalytics = () => {
  const [initialised, setInitialised] = useState(false);
  const [infinityAnalyticsRows, setInfinityAnalyticsRows] = useState();
  const [pageSize, setPageSize] = useState(10);

  const isMounted = useMounted();

  const initialise = useCallback(async () => {
    if (!isMounted()) {
      return;
    }

    // Define dates from Infinity launch to today
    const dates = [];
    const launchDate = moment('2024-06-10 00:00:00');
    let currentDate = moment().utc();
    while (currentDate > launchDate) {
      dates.push({ key: currentDate.format('YYYY-MM-DD'), label: currentDate.format('DD/MM/YYYY')});
      currentDate = currentDate.subtract(1, 'days');
    }

    const infinityLaunchAnalytics = await requestApi.getResponse({
      url: 'infinity/analytics/launch'
    });

    // Build the DataGrid rows
    const rows = dates.map((date) => {
      const { key: dateKey, label: dateLabel } = date;
      let orderUnitCountTotal = 0;
      Object.keys(infinityLaunchAnalytics.orderUnitCountsIndexedOnOrderCreatedAtAndOrderUnitSku[dateKey] ?? []).forEach((orderUnitSku) => {
        orderUnitCountTotal += infinityLaunchAnalytics.orderUnitCountsIndexedOnOrderCreatedAtAndOrderUnitSku[dateKey][orderUnitSku];
      });
      const orderUnitCount10 = infinityLaunchAnalytics.orderUnitCountsIndexedOnOrderCreatedAtAndOrderUnitSku[dateKey]?.['INF001-10'] ?? 0;
      const orderUnitCount20 = infinityLaunchAnalytics.orderUnitCountsIndexedOnOrderCreatedAtAndOrderUnitSku[dateKey]?.['INF001-20'] ?? 0;
      const orderUnitCount50 = infinityLaunchAnalytics.orderUnitCountsIndexedOnOrderCreatedAtAndOrderUnitSku[dateKey]?.['INF001-50'] ?? 0;
      const orderUnitCount100 = infinityLaunchAnalytics.orderUnitCountsIndexedOnOrderCreatedAtAndOrderUnitSku[dateKey]?.['INF001-100'] ?? 0;
      const orderUnitCountPlus = infinityLaunchAnalytics.orderUnitCountsIndexedOnOrderCreatedAtAndOrderUnitSku[dateKey]?.INFP001 ?? 0;
      return {
        id: dateKey,
        date: dateLabel,
        orderUnitCount: orderUnitCountTotal,
        orderUnitCountAnalysis:
          `${orderUnitCount10} / ${orderUnitCount20} / ${orderUnitCount50} / ${orderUnitCount100} / ${orderUnitCountPlus}`,
        publishedQuizVersionCount: infinityLaunchAnalytics.publishedQuizVersionCountsIndexedOnPublishedAt[dateKey] ?? 0,
        quizVersionShareCount: infinityLaunchAnalytics.quizVersionShareCountsIndexedOnCreatedAt[dateKey] ?? 0,
        quizSessionCount: infinityLaunchAnalytics.quizSessionCountsIndexedOnCreatedAt[dateKey] ?? 0,
        questionResultCount: infinityLaunchAnalytics.questionResultCountsIndexedOnCreatedAt[dateKey] ?? 0,
      };
    });
    setInfinityAnalyticsRows(rows);
    setInitialised(true);
  }, [isMounted]);

  useEffect(() => {
    initialise();
  }, [initialise]);

  if (!initialised) return <LoadingSpinner/>;

  return (
    <Grid
      item
      xs={12}
    >
      <Card>
        <CardHeader color="info">Infinity launch</CardHeader>
        <CardContent>
          <DataGrid
            autoHeight
            columns={columns}
            components={{ Toolbar: ExportContainer }}
            disableSelectionOnClick
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            pageSize={pageSize}
            rows={infinityAnalyticsRows}
            rowsPerPageOptions={[10, 30, 100]}
          />
        </CardContent>
      </Card>
    </Grid>
  );
}

export default InfinityAnalytics;
